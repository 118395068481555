import React from "react";
import Navbar from "../../components/layout/Navbar";
import emailjs from "@emailjs/browser";

export default function Contact() {
	function sendEmail(e) {
		e.preventDefault();

		emailjs
			.sendForm(
				"service_4qy5ms8",
				"default_2022SRII",
				e.target,
				"user_ucq09q6DItquvb6SAbMbO"
			)
			.then(
				(result) => {
					console.log(result.text);
				},
				(error) => {
					console.log(error.text);
				}
			);
		e.target.reset();
	}

	return (
		<div className="contact-parent">
			<Navbar title="OTBxDesigns" className="nav-title" />
			<div className="container2">
				<div className="title">
					<h3>Lets talk!</h3>

					<p>
						Need a project built?
						<br />
						Want to build together?
					</p>
					<div className="contact__photo"></div>
				</div>

				<form className="contact-form" onSubmit={sendEmail}>
					<div className="input">
						<label htmlFor="">
							<span className="req">*</span>
							Name:{" "}
						</label>
						<input type="text" name="name" className="contact-input" required />
					</div>

					<span>
						<br></br>
					</span>
					<div className="input">
						<label htmlFor="">
							<span className="req">*</span>
							Email:{" "}
						</label>
						<input
							type="email"
							className="contact-input"
							name="email"
							required
						/>
					</div>

					<span>
						<br></br>
					</span>
					<div className="input">
						<label htmlFor="" className="subject">
							<span className="req">*</span>
							Subject:{" "}
						</label>

						<input
							type="text"
							className="contact-input svg-input"
							name="subject"
							required
						/>
					</div>

					<span>
						<br></br>
					</span>
					<div className="input">
						<label htmlFor="" className="message">
							<span className="req">*</span>
							Message:{" "}
						</label>
						<textarea
							type="text"
							className="contact-input2"
							maxLength="200"
							name="message"
							required
						/>
					</div>

					<span>
						<br></br>
					</span>
					<input type="submit" className="submit" value="Get In Contact" />
				</form>
			</div>
		</div>
	);
}
