import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/layout/Navbar";

const About = () => {
	return (
		<div>
			<Navbar title="OTBxDesigns" className="nav-title" />

			<h2 className="title">ABOUT</h2>
			<div className="svg">
				<svg
					xmlns="https://www.w3.org/2000/svg"
					width="174.184"
					height="160.382"
					viewBox="0 0 174.184 160.382"
				>
					<defs>
						<pattern
							id="pattern"
							width="1"
							height="1"
							viewBox="30 0 174.184 166.382"
						>
							<image
								preserveAspectRatio="xMidYMid meet"
								width="248"
								height="166.382"
								href="https://i.imgur.com/wapA12e.jpg"
							/>
						</pattern>
					</defs>
					<g
						id="Path_80"
						data-name="Path 80"
						transform="translate(0 76.698)"
						fill="url(#pattern)"
					>
						<path
							d="M 127.265510559082 82.68384552001953 L 43.52886962890625 82.68384552001953 L 1.000001430511475 36.18202972412109 L 1.000001430511475 -23.9474925994873 L 43.56060028076172 -75.69774627685547 L 127.2340545654297 -75.69774627685547 L 173.18359375 -23.92611885070801 L 173.18359375 36.15998840332031 L 127.265510559082 82.68384552001953 Z"
							stroke="none"
						/>
						<path
							d="M 44.03292846679688 -74.69776153564453 L 1.999984741210938 -23.5890941619873 L 1.999984741210938 35.79372787475586 L 43.96945190429688 81.683837890625 L 126.8474578857422 81.683837890625 L 172.1835784912109 35.7496337890625 L 172.1835784912109 -23.54635810852051 L 126.784538269043 -74.69776153564453 L 44.03292846679688 -74.69776153564453 M 43.08827209472656 -76.69776153564453 L 127.6835632324219 -76.69776153564453 L 174.1835784912109 -24.30587959289551 L 174.1835784912109 36.57038116455078 L 127.6835632324219 83.683837890625 L 43.08827209472656 83.683837890625 L -1.52587890625e-05 36.57038116455078 L -1.52587890625e-05 -24.30587959289551 L 43.08827209472656 -76.69776153564453 Z"
							stroke="none"
							fill="#fff"
						/>
					</g>
				</svg>
			</div>
			<div className="about">
				<p className="about-title">Front End Web Developer</p>
				<p className="about-body">
					Self-taught web developer building functional, responsive, & visually
					pleasing solutions.
				</p>
				<p className="about-contact">If you need it, I build it!</p>

				<Link to="/contact">
					<input type="submit" value="Contact Me" className="submit" />
				</Link>
			</div>
		</div>
	);
};

export default About;
