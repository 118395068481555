import { React, useState } from "react";
import "../../../src/App.css";

export default function GigiModal() {
	const [Gigimodal, setGigiModal] = useState(false);

	const toggleModal = () => {
		setGigiModal(!Gigimodal);
	};

	return (
		<>
			<div className="wall__card" onClick={toggleModal}>
				<div className="wall__card--front card--gigi"></div>

				<div className="wall__card--back">
					<div className="back--content">
						<p className="back__title">Gigi</p>
						<p className="back__para">Restaraunt</p>
					</div>
					<p className="">UI/UX</p>
				</div>
			</div>

			{Gigimodal && (
				<div className="modal">
					<div onClick={toggleModal} className="overlay"></div>

					<div className="modal-content">
						<div className="modal-content--title">
							<h2>Gigi's Bakery LA</h2>
						</div>

						<div className="content__contatiner">
							<div className="content__project--gigi">
								<a href="https://gigisla.netlify.app/" className="launch">
									<p className="launch__para">LAUNCH PROJECT</p>
								</a>
							</div>
							{/* --##OVERVIEW##-- */}
							<div className="content__overview--gigi">
								<p class="content__overview--title">OVERVIEW</p>
								<p className="content__overview--para">
									Restaraunt site overhaul based on UI/UX study conducted by
									myself. My goal is improve the overall UI/UX that could
									potential convert into more customers.
								</p>
							</div>
							{/* --##CHALLENGES##-- */}
							<div className="content__challenges--gigi">
								<p class="content__challenges--title">PROBLEM</p>
								<p className="content__challenges--para">
									Current website has navigations issues, duplicate information,
									& an overall bland feel too it. Customers can view the menu
									here but it is not current either.
								</p>
							</div>
							{/* --##SOLUTION##-- */}
							<div className="content__solution--gigi">
								<p class="content__solution--title">SOLUTION</p>
								<p className="content__solution--para">
									Utilizing React and CSS grid, a responsive and interactive
									layout was designed to improve the overall site feel. With
									more color, responsive scaling this modern layout is more
									inviting to users.
								</p>
							</div>
							{/* --##TECH##-- */}
							<div className="content__tech--gigi">
								<p className="content__tech--title">TECH</p>
								<p className="content__tech--para">
									<li>
										<ul>Adobe XD</ul>
										<ul>React</ul>
										<ul>Reactstrap</ul>
										<ul>Mobile First Principles</ul>
									</li>
								</p>
							</div>
							{/* --##INFO##-- */}
							<div className="content__info--gigi">
								<p className="content__project--title">
									Project
									<p className="content__project--para">Website</p>
								</p>
								<p className="content__team--title">
									Team
									<p className="content__team--para">Steven Riley</p>
								</p>
								<p className="content__role--title">
									Role
									<p className="content__role--para">
										<ul>Frontend Developer</ul>
										<ul>UI/UX Design</ul>
									</p>
								</p>
								<p className="content__date--title">
									Date
									<p className="content__date--para">2023</p>
								</p>
							</div>
						</div>

						<button onClick={toggleModal} className="close-modal">
							X
						</button>
					</div>
				</div>
			)}
		</>
	);
}
