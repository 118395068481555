import React from "react";
import Navbar from "../../components/layout/Navbar";
import "../../../src/App.css";
import Modal from "../modals/Quickshot_modal";
import KModal from "../modals/Kilm_modal";
import CwfModal from "../modals/Cwf_modal";
import GigiModal from "../modals/Gigi";
import WholisticModal from "../modals/Wholistic";

const Work = () => {
	return (
		<>
			<section id="work">
				<Navbar title="OTBxDesigns" className="nav-title" />
				<div className="title">
					Welcome to MY PROJECTS
					<p>Feel free to browse. Feedback is always welcomed.</p>
				</div>
				<div class="wall">
					<WholisticModal />

					<GigiModal />

					<CwfModal />

					<KModal />

					<Modal />

					{/* <BoltModal /> */}
					{/* <ArtModal /> */}
					{/* <FilmzModal /> */}
				</div>
			</section>
		</>
	);
};

export default Work;
