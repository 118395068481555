import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const Navbar = () => {
	return (
		<nav className="navbar">
			<ul>
				<NavLink
					exact
					to="/"
					className={({ isActive }) => (isActive ? "active-link" : "link")}
				>
					HOME
				</NavLink>
				<span className="space2">|</span>
				<NavLink
					exact
					to="/work"
					// className="nav-links"
					className={({ isActive }) => (isActive ? "active-link" : "link")}
				>
					PORTFOLIO
				</NavLink>
				<span className="space2">|</span>
				{/* <NavLink
					exact
					to="/about"
					className="nav-links"
					className={({ isActive }) => (isActive ? "active-link" : "link")}
				>
					ABOUT
				</NavLink>
				<span className="space2">|</span> */}
				<NavLink
					exact
					to="/contact"
					// className="nav-links"
					className={({ isActive }) => (isActive ? "active-link" : "link")}
				>
					CONTACT
				</NavLink>
			</ul>
		</nav>
	);
};

Navbar.defaultProps = {
	title: "OTBxDesigns",
};

Navbar.propTypes = {
	title: PropTypes.string.isRequired,
};

export default Navbar;
