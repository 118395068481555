import { React, useState } from "react";
import "../../../src/App.css";

export default function KModal() {
	const [Kmodal, setKModal] = useState(false);

	const toggleModal = () => {
		setKModal(!Kmodal);
	};

	return (
		<>
			<div className="wall__card" onClick={toggleModal}>
				<div className="wall__card--front card--kilm"></div>

				<div className="wall__card--back">
					<div className="back--content">
						<p className="back__title">Kilm</p>
						<p className="back__para">Fashion Newletter</p>
					</div>
					<p className="">HTML Email (Newletter)</p>
				</div>
			</div>

			{Kmodal && (
				<div className="modal">
					<div onClick={toggleModal} className="overlay"></div>

					<div className="modal-content">
						<div className="modal-content--title">
							<h2>KILM</h2>
						</div>

						<div className="content__contatiner">
							<div className="content__project--kilm">
								<a href="https://ptblxnk.github.io/kilm/" className="launch">
									<p className="launch__para">LAUNCH PROJECT</p>
								</a>
							</div>
							{/* --##OVERVIEW##-- */}
							<div className="content__overview--kilm">
								<p class="content__overview--title">OVERVIEW</p>
								<p className="content__overview--para">
									Creating a newletter following minimalism cues, the flow of
									information is easy to digest as the subscribers progress
									through newletter.
								</p>
							</div>
							{/* --##CHALLENGES##-- */}
							<div className="content__challenges--kilm">
								<p class="content__challenges--title">CHALLENGE</p>
								<p className="content__challenges--para">
									Creating a purposeful newsletter forma thats not overcrowded
									or scarce of info/media that could otherwise take away from
									the users' experience.
								</p>
							</div>
							{/* --##SOLUTION##-- */}
							<div className="content__solution--kilm">
								<p class="content__solution--title">SOLUTION</p>
								<p className="content__solution--para">
									The minimlist design keeps the newletters' intent clear &
									provides an unterupted flow, keeping readers engaged. To
									improve the formats effectiveness, intuitive CTA's to
									site-related content such as webisodeS & an online store are
									incorporated.
								</p>
							</div>
							{/* --##TECH##-- */}
							<div className="content__tech--kilm">
								<p className="content__tech--title">TECH</p>
								<p className="content__tech--para">
									<li>
										<ul>HTML</ul>
										<ul>CSS</ul>
										<ul>AdobeXD</ul>
									</li>
								</p>
							</div>
							{/* --##INFO##-- */}
							<div className="content__info--kilm">
								<p className="content__project--title">
									Project
									<p className="content__project--para">HTML Email</p>
								</p>
								<p className="content__team--title">
									Team
									<p className="content__team--para">Steven Riley</p>
								</p>
								<p className="content__role--title">
									Role
									<p className="content__role--para">
										<ul>HTML Email Developer</ul>
										<ul>UI/UX </ul>
										<ul>Testing</ul>
									</p>
								</p>
								<p className="content__date--title">
									Date
									<p className="content__date--para">2022</p>
								</p>
							</div>
						</div>

						<button onClick={toggleModal} className="close-modal">
							X
						</button>
					</div>
				</div>
			)}
		</>
	);
}
