import { React, useState } from "react";
import "../../../src/App.css";

export default function CwfModal() {
	const [Cwfmodal, setCwfModal] = useState(false);

	const toggleModal = () => {
		setCwfModal(!Cwfmodal);
	};

	return (
		<>
			<div className="wall__card" onClick={toggleModal}>
				<div className="wall__card--front card--cwf"></div>

				<div className="wall__card--back">
					<div className="back--content">
						<p className="back__title">ClutchWerks Factory</p>
						<p className="back__para">Car Racing</p>
					</div>
					<p className="">Website</p>
				</div>
			</div>

			{Cwfmodal && (
				<div className="modal">
					<div onClick={toggleModal} className="overlay"></div>

					<div className="modal-content">
						<div className="modal-content--title">
							<h2>CWF</h2>
						</div>

						<div className="content__contatiner">
							<div className="content__project--cwf">
								<a href="https://ptblxnk.github.io/CWF/" className="launch">
									<p className="launch__para">LAUNCH PROJECT</p>
								</a>
							</div>
							{/* --##OVERVIEW##-- */}
							<div className="content__overview--cwf">
								<p class="content__overview--title">OVERVIEW</p>
								<p className="content__overview--para">
									Promotional email for new social app, QuickShot. Choose
									between two profile types to start your journey.
								</p>
							</div>
							{/* --##CHALLENGES##-- */}
							<div className="content__challenges--cwf">
								<p class="content__challenges--title">PROBLEM</p>
								<p className="content__challenges--para">
									Some promotional emails can seem overcrowded with too much
									infromation with the message getting lost as a result.
								</p>
							</div>
							{/* --##SOLUTION##-- */}
							<div className="content__solution--cwf">
								<p class="content__solution--title">SOLUTION</p>
								<p className="content__solution--para">
									A minimlist design was chosen to better direct the viewer to
									the core of campany and their message. This template is
									simple, intuitaive, and keeps viewer engaged.
								</p>
							</div>
							{/* --##TECH##-- */}
							<div className="content__tech--cwf">
								<p className="content__tech--title">TECH</p>
								<p className="content__tech--para">
									<li>
										<ul>HTML</ul>
										<ul>CSS</ul>
										<ul>AdobeXD</ul>
									</li>
								</p>
							</div>
							{/* --##INFO##-- */}
							<div className="content__info--cwf">
								<p className="content__project--title">
									Project
									<p className="content__project--para">Website</p>
								</p>
								<p className="content__team--title">
									Team
									<p className="content__team--para">Steven Riley</p>
								</p>
								<p className="content__role--title">
									Role
									<p className="content__role--para">
										<ul>Frontend Developer</ul>
										<ul>UI/UX </ul>
										<ul>Testing</ul>
									</p>
								</p>
								<p className="content__date--title">
									Date
									<p className="content__date--para">2022</p>
								</p>
							</div>
						</div>

						<button onClick={toggleModal} className="close-modal">
							X
						</button>
					</div>
				</div>
			)}
		</>
	);
}
