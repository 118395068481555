import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PropTypes from "prop-types";
import About from "./components/pages/About";
import Work from "./components/pages/Work";
import Home from "./components/pages/Home";
import Contact from "./components/pages/Contact";
// import Navbar from "./components/layout/Navbar";

class App extends Component {
	static defaultProps = {
		title: "OTBxDesigns",
	};

	static propTypes = {
		title: PropTypes.string.isRequired,
	};

	render() {
		return (
			<Router>
				<div className="App">
					<div className="container">
						<Routes>
							<Route className="nav-links" exact path="/" element={<Home />} />
							<Route
								className="nav-links"
								exact
								path="/about"
								element={<About />}
							/>
							<Route
								className="nav-links"
								exact
								path="/work"
								element={<Work />}
							/>
							<Route
								className="nav-links"
								exact
								path="/contact"
								element={<Contact />}
							/>
						</Routes>
					</div>
					<footer className="footer">
						Copyright &copy; 2022, OTBxD, All Rights Reserved.
					</footer>
				</div>
			</Router>
		);
	}
}

export default App;
