import { React, useState } from "react";
import "../../../src/App.css";

export default function WholisticModal() {
	const [Wholisticmodal, setWholisticModal] = useState(false);

	const toggleModal = () => {
		setWholisticModal(!Wholisticmodal);
	};

	return (
		<>
			<div className="wall__card" onClick={toggleModal}>
				<div className="wall__card--front card--wholistic"></div>

				<div className="wall__card--back">
					<div className="back--content">
						<p className="back__title">Wholistic Healing</p>
						<p className="back__para">Therapy Services</p>
					</div>
					<p className="">Website</p>
				</div>
			</div>

			{Wholisticmodal && (
				<div className="modal">
					<div onClick={toggleModal} className="overlay"></div>

					<div className="modal-content">
						<div className="modal-content--title">
							<h2>Wholistic Healing</h2>
						</div>

						<div className="content__contatiner">
							<div className="content__project--wholistic">
								<a
									href="https://www.wholelistic-healing.com/"
									className="launch"
								>
									<p className="launch__para">LAUNCH PROJECT</p>
								</a>
							</div>
							{/* --##OVERVIEW##-- */}
							<div className="content__overview--wholistic">
								<p class="content__overview--title">OVERVIEW</p>
								<p className="content__overview--para">
									Custom site built for client using Shopify's CMS platform.
									Utilized Adobe XD to protoype UI to final product.
								</p>
							</div>
							{/* --##CHALLENGES##-- */}
							<div className="content__challenges--wholistic">
								<p class="content__challenges--title">PROBLEM</p>
								<p className="content__challenges--para">
									The client had no online presence. The client offers a variety
									of therapeutic solitions and wants to reach a much larger
									audience.
								</p>
							</div>
							{/* --##SOLUTION##-- */}
							<div className="content__solution--wholistic">
								<p class="content__solution--title">SOLUTION</p>
								<p className="content__solution--para">
									Provided an online presence for the cleint by creating an
									effective website that displays all services, broadcast
									newsletters, and managed meetings.
								</p>
							</div>
							{/* --##TECH##-- */}
							<div className="content__tech--wholistic">
								<p className="content__tech--title">TECH</p>
								<p className="content__tech--para">
									<li>
										<ul>Adobe XD</ul>
										<ul>Custom CSS & Animations</ul>
										<ul>CMS Management</ul>
									</li>
								</p>
							</div>
							{/* --##INFO##-- */}
							<div className="content__info--wholistic">
								<p className="content__project--title">
									Project
									<p className="content__project--para">Website</p>
								</p>
								<p className="content__team--title">
									Team
									<p className="content__team--para">Steven Riley</p>
								</p>
								<p className="content__role--title">
									Role
									<p className="content__role--para">
										<ul>Frontend Developer</ul>
										<ul>UI/UX Design</ul>
									</p>
								</p>
								<p className="content__date--title">
									Date
									<p className="content__date--para">2023</p>
								</p>
							</div>
						</div>

						<button onClick={toggleModal} className="close-modal">
							X
						</button>
					</div>
				</div>
			)}
		</>
	);
}
