import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const Home = ({ title }) => {
	return (
		<section class="home">
			<div class="name">
				<p class="name__para">Steven Riley</p>
			</div>

			<div class="titles">
				<div class="carousel">
					<div class="pre">
						<hr class="pre__tab" />
					</div>
					<div class="change_outer">
						<div class="change_inner">
							<ol class="titles__name">
								<li class="title__item">Web Developer</li>
								<li class="title__item">Engineer</li>
								<li class="title__item">UI Designer</li>
								<li class="title__item">Photographer</li>
								<li class="title__item">Creator</li>
							</ol>
						</div>
					</div>
				</div>
			</div>

			<div class="about">
				<p class="about__para">
					I enjoy creating fun and engaging experiences people will remember.
					I'm always looking for new ways & inspirations to improve the user
					experience as our curiousity and talent continues to grow.
				</p>
			</div>

			<div class="slide">
				<Link to="/work" className="button__work">
					See My Work
				</Link>
			</div>
		</section>
	);
};

Home.defaultProps = {
	title: "OTBxDesigns",
};

Home.propTypes = {
	title: PropTypes.string.isRequired,
};

export default Home;
