import { React, useState } from "react";
import "../../../src/App.css";

export default function Modal() {
	const [modal, setModal] = useState(false);

	const toggleModal = () => {
		setModal(!modal);
	};

	return (
		<>
			<div className="wall__card" onClick={toggleModal}>
				<div className="wall__card--front card--qs"></div>
				<div className="wall__card--back">
					<div className="back--content back__img-qs">
						<p className="back__title">QuickShot</p>
						<p className="back__para">Service for Creatives</p>
					</div>
					<p className="">HTML Email (Promo)</p>
				</div>
			</div>

			{modal && (
				<div className="modal">
					<div onClick={toggleModal} className="overlay"></div>

					<div className="modal-content">
						<div className="modal-content--title">
							<h2>QUICKSHOT</h2>
						</div>

						<div className="content__contatiner">
							<div className="content__project--qs">
								<a href="https://ptblxnk.github.io/qs/" className="launch">
									<p className="launch__para">LAUNCH PROJECT</p>
								</a>
							</div>
							{/* --##OVERVIEW##-- */}
							<div className="content__overview--qs">
								<p class="content__overview--title">OVERVIEW</p>
								<p className="content__overview--para">
									Promotional email for new social app, QuickShot. Choose
									between two profile types to start your journey.
								</p>
							</div>
							{/* --##CHALLENGES##-- */}
							<div className="content__challenges--qs">
								<p class="content__challenges--title">PROBLEM</p>
								<p className="content__challenges--para">
									Some promotional emails can seem overcrowded with too much
									infromation with the message getting lost as a result.
								</p>
							</div>
							{/* --##SOLUTION##-- */}
							<div className="content__solution--qs">
								<p class="content__solution--title">SOLUTION</p>
								<p className="content__solution--para">
									A minimlist design was chosen to better direct the viewer to
									the core of campany and their message. This template is
									simple, intuitaive, and keeps viewer engaged.
								</p>
							</div>
							{/* --##TECH##-- */}
							<div className="content__tech--qs">
								<p className="content__tech--title">TECH</p>
								<p className="content__tech--para">
									<li>
										<ul>HTML</ul>
										<ul>CSS</ul>
										<ul>AdobeXD</ul>
									</li>
								</p>
							</div>
							{/* --##INFO##-- */}
							<div className="content__info--qs">
								<p className="content__project--title">
									Project
									<p className="content__project--para">HTML Email</p>
								</p>
								<p className="content__team--title">
									Team
									<p className="content__team--para">Steven Riley</p>
								</p>
								<p className="content__role--title">
									Role
									<p className="content__role--para">
										<ul>HTML Email Developer</ul>
										<ul>UI/UX </ul>
										<ul>Testing</ul>
									</p>
								</p>
								<p className="content__date--title">
									Date
									<p className="content__date--para">2022</p>
								</p>
							</div>
						</div>

						<button onClick={toggleModal} className="close-modal">
							X
						</button>
					</div>
				</div>
			)}
		</>
	);
}
